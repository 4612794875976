@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #f9f2f2;
  --secondary-color: #135432;
  --black-color: #030303;
  --lightGray-color: #f7f7f7;
  --gray-color: #561139;
  --brown-color: #3b1e43;
  --white-color: #fefefe;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}